.flip-card {
    perspective: 1000px;
    width: 150px;
    height: 200px;
    transition: transform 0.6s;
}

.flip-card:hover {
    cursor: pointer;
    transform: scale(1.1);
    transition: transform 0.6s;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    cursor: pointer;
    box-shadow: 0 4px 10px 0 rgba(132, 128, 177, 0.28);
    border-radius: 10px;
}

.is-flipped {
    transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.flip-card-front img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 7px;
}

.flip-card-front .description {
    color: #000000;
    padding: 10px;
    text-align: center;
    font-size: 0.8rem;
}

.flip-card-front {
    background-color: rgb(221, 221, 221);
    color: #272727;
    border: 2px solid #ffffff00;
    border-radius: 10px;
}

.flip-card-back {
    background-color: #eee;
    color: #272727;
    transform: rotateY(180deg);
    padding: 0px;
    border-radius: 10px;
}

.back-content {
    position: fixed;
    top: 15px;
    font-size: 12px;
    margin: 15px;

}

.certificate-link {
    position: fixed;
    bottom: 10px;
    padding: 10px;
    background-color: rgba(102, 99, 99, 0.253);
    color: #000000;
    text-decoration: none;
    border-radius: 3px;
    font-size: 10px;
}

.no-certificate {
    position: fixed;
    bottom: 10px;
    padding: 10px;
    background-color: rgba(102, 99, 99, 0.253);
    color: rgb(153, 153, 153);
    text-decoration: none;
    border-radius: 3px;
    font-size: 10px;
}

@media only screen and (max-width: 800px) {
    .flip-card {
        width: 120px;
    }
}

@media only screen and (max-width: 480px) {
    .flip-card {
        width: 120px;
    }

}