.overall {
  font-family: 'Noto Sans Mono';
}

.timeline-header {
  width: 100%;
  text-align: center;
  color: #272727;
}

.sub-header {
  margin-top: 0;    
  margin-bottom: 0; 
}

.img-logo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.skill-bubble {
  display: inline-block;
  background-color: rgb(128, 124, 124);
  color: white;
  padding: 2px 6px;
  margin: 5px;
  border-radius: 10px;
  font-size: 14px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* @media only screen and (max-width: 1300px) {
  .img-logo {
    width: 40px;
    height: 40px;
  }
} */

@media only screen and (max-width: 1169px) {
  .img-logo {
    width: 40px;
    height: 40px;
  }
}