.classes-card {
  background-color: rgb(221, 221, 221);
  padding: 20px;
  margin: 10px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: left;
  box-shadow: 0 4px 10px 0 rgba(132, 128, 177, 0.28);
  height: auto;
  min-height: 490px;
  transition: transform 0.6s ease;
}

.classes-card:hover {
  transform: scale(1.02);
  transition: transform 0.6s ease;
}
.course-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.course-semester {
  font-weight: normal;
  color: #555;
  font-size: 10px;
  margin-top: 4px;
  margin-bottom: 10px;
}

.course-description {
  font-size: 13px;
  margin-bottom: 10px;
}

.classes-card .skill-bubbles {
  display: flex;
  flex-wrap: wrap;
}

.classes-card .skill-bubbles .skill-bubble {
  display: inline-block;
  background-color: rgb(128, 124, 124);
  color: white;
  padding: 2px 6px;
  margin: 5px;
  border-radius: 10px;
  text-align: center;
  font-size: 10px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.349);
}