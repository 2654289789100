.navbar {
  width: 100%;
  height: 100px;
  background: #21325e;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Noto Sans Mono';
  /* box-shadow: 0 9px 25px 0 rgba(132,128,177,0.28); */
}

.links {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; 
}

.navbar a {
  color: white;
  text-decoration: none;
  padding: 10px 20px; 
  margin: 0 10px; 
  font-size: 25px;
  transition: background-color 0.3s, color 0.3s; 
}

.navbar a.active {
  background-color: #f0f0f0;
  color: #21325e;
  border-radius: 5px;
  box-shadow: 0 5px 25px 2px rgba(48, 48, 48, 0.28); 
}

.navbar a:hover,
.navbar a:focus { 
  background-color: #f0f0f0;
  color: #21325e;
  border-radius: 5px; 
}

.toggleButton {
  display: none; 
}

@media only screen and (max-width: 600px) {
  .navbar {
    height: auto; 
    flex-direction: column; 
    padding: 10px 0; 
  }

  .toggleButton {
    width: 100%;
    justify-content: space-between; 
    padding: 0 10px; 
    order: 1; 
  }

  .toggleButton svg {
    font-size: 40px; 
  }

  .links {
    width: 100%;
    height: auto; 
    flex-direction: row; 
    flex-wrap: nowrap; 
    justify-content: space-around; 
    order: 0; 
  }

  .navbar a {
    font-size: 16px;
    padding: 8px 10px; 
    margin: 0; 
  }

  #open {
    height: auto; 
  }

  #open .links {
    flex-direction: row;
  }
}

#open {
  height: auto; 
}

#open .links {
  flex-direction: column;
  align-items: center; 
}

#open a {
  width: 100%; 
  padding: 15px; 
  margin: 5px 0; 
  border-bottom: 1px solid white; 
}

#open a:last-child {
  border-bottom: none;
}
