.dynamic-text-container {
    display: flex;
    font-family: 'Noto Sans Mono';
    justify-content: center; 
    align-items: center; 
    height: 100px; 
    font-size: 2.0em; 
    color: #f0f0f0c0; 
    margin: 10px 0; 
    white-space: nowrap; 
  }
  
  .dynamic-text-container .typed-cursor {
    color: #f0f0f0c0; 
    opacity: 1; 
    animation: blink 0.8s infinite; 
  }
  
  @keyframes blink {
    50% { opacity: 0; }
  }
  
  @media only screen and (max-width: 768px) {
    .dynamic-text-container {
        font-size: 2.0em; 
        white-space: normal; 
      }
  }

  @media only screen and (max-width: 480px) {
    .dynamic-text-container {
        margin-top: 0px;
        font-size: 1.5em; 
        white-space: normal; 
      }
  }
  