.home {
  width: 100%;
  align-items: center;
  font-family: 'Noto Sans Mono';
  color: #272727;

}

.profile-container {
  display: flex;
  perspective: 1000px;
  width: 300px;
  height: 300px;
  margin-top: 20px;
  margin-bottom: 0px;
  align-items: center;
  transition: transform 0.3s ease-in-out;
  transform-origin: center;
}

.profile-container,
.profile-container:hover {
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.profile-container:hover {
  transform: scale(1.1);
}

.profile-image {
  display: flex;
  border-color: #ffffff;
  border-style: outset;
  border-radius: 50%;
  width: 300px;
  height: 300px;
  object-fit: cover;
  backface-visibility: hidden;
  position: absolute;
  transition: transform 0.6s ease;
  box-shadow: 0 9px 25px 0 rgba(48, 48, 48, 0.28);
}

.profile-container.flipped .front,
.profile-container.flipped .back {
  transition: transform 0.6s ease;
}

.profile-container.flipped .front {
  transform: rotateY(180deg);
}

.profile-container.flipped .back {
  transform: rotateY(0deg);
}

.front {
  transform: rotateY(0deg);
}

.back {
  transform: rotateY(-180deg);
}

.about {
  width: 100%;
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #21325e;
  color: #f0f0f0;
  box-shadow: 0 25px 25px 0 rgba(132, 128, 177, 0.28);

}

.about h2 {
  margin-top: 10px;
  margin-bottom: 40px;
  font-size: 80px;
  color: #e9d35b;
  height: 50px;
}

.about .tagline {
  margin-top: -30px;
  width: 50%;
  font-size: 30px;
}

.about .media {
  width: 30%;
  font-size: 30px;
  margin-bottom: 20px;
}

.media svg {
  font-size: 60px;
  margin: 5px;
  color: white;
}

.skills {
  font-size: 35px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: left;
  max-width: 700px;
  margin: auto;
  overflow: hidden;
}

.skills h2 {
  color: #272727;
  font-size: 30px;
}

.skills .body-text {
  font-size: 20px;
}

.skill-badges {
  text-align: center;
}

.skill-icon {
  transition: transform 0.3s ease;
}

.skill-icon:hover {
  transform: scale(1.1);
}

.certifications-container {
  display: grid;
  height: auto;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
  padding: 20px;
  align-items: center;
}

.classes-container {
  display: grid;
  height: auto;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  padding: 20px;
  align-items: center;
}

@media only screen and (max-width: 800px) {
  .profile-container {
    width: 200px;
    height: 200px;
  }

  .profile-image {
    width: 200px;
    height: 200px;
  }

  .about h2 {
    font-size: 40px;
  }

  .about .tagline {
    font-size: 20px;
    width: 80%;
  }

  .about .media {
    font-size: 20px;
    width: 80%;
  }

  .skills {
    text-align: left;
    padding: 10px;
  }

  .skills .body-text {
    font-size: 15px;
  }

  .skill-badges {
    text-align: center;
  }

  .skills h2 {
    font-size: 30px;
  }

  .certifications-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 20px;
  }

  .certifications-container-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 480px) {
  .profile-container {
    width: 200px;
    height: 200px;
  }

  .profile-image {
    width: 200px;
    height: 200px;
  }

  .about h2 {
    font-size: 40px;
    margin-top: 30px;
    margin-bottom: -10px;
  }

  .about .tagline {
    margin-top: -50px;
    font-size: 20px;
    width: 80%;
  }

  .about .media {
    font-size: 20px;
    width: 80%;
  }

  .skills {
    text-align: left;
    padding: 10px;
    max-width: 300px;
  }

  .skills .body-text {
    font-size: 15px;
    padding-right: 10px;

  }

  .skill-badges {
    text-align: center;
  }

  .list {
    padding: 0;
  }

  .skills h2 {
    font-size: 30px;
  }

  .certifications-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 20px;
  }
}