.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999999999999;
}

.modalContent {
    background: rgb(221, 221, 221);
    padding: 20px;
    border-radius: 10px;
    position: relative;
    max-width: 500px;
    max-height: 90vh;
    width: 90%;
    overflow-y: auto;
}

.modalCloseButton {
    position: absolute;
    background-color: rgb(175, 173, 173);
    top: 10px;
    right: 10px;
    border-width: 0px;
    border-radius: 5px;
    border-color: transparent;
    font-size: 1.5rem;
    cursor: pointer;
}

.modalImage {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    margin-bottom: 20px;
}

.modalContent .skills {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;
}

@media only screen and (max-width: 800px) {
    .modalContent {
        max-width: 400px;
        max-height: 80vh;
        padding: 15px;
        font-size: 0.8rem;
    }
}
  
@media only screen and (max-width: 480px) {
    .modalContent {
        max-width: 300px;
        max-height: 80vh;
        padding: 10px;
        font-size: 0.8rem;
    }
}